import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark, shLink } from '@beyondtrust/shield-icons';
import { typographyData } from './typography-data';

@Component({
	selector: 'app-typography',
	templateUrl: './typography.component.html',
	styleUrls: ['./typography.component.less'],
})
export class TypographyComponent {
	public currVisible = 'overview';
	public currTyporgraphySort = 'component';
	public data = typographyData;
	public sortedStyles = [];
	public sortedSections = [];
	public sortedSectionsWithUrl = [];
	public tempStyles = [];
	public tempSections = [];
	public currName = '';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public linkIcon = shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Fonts (typefaces)',
			value: 'fonts',
			empty: false,
			children: [
				{
					name: 'Inter',
					value: 'inter',
				},
				{
					name: 'Noto Sans',
					value: 'noto-sans',
				},
				{
					name: 'Fira code',
					value: 'fira-code',
				},
			],
		},
		{
			name: 'Type ramp (font stack)',
			value: 'type-ramp',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Heading',
					value: 'headers',
				},
				{
					name: 'Button',
					value: 'button',
				},
				{
					name: 'Label',
					value: 'label',
				},
				{
					name: 'Body',
					value: 'body',
				},
				{
					name: 'Instructional text',
					value: 'instructional',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'Supporting content',
					value: 'supporting',
				},
			],
		},
		{
			name: 'HTML tags and page hierarchy',
			value: 'html-tags-hierarchy',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
			],
		},
		{
			name: 'Line length and alignment',
			value: 'line-length-and-alignment',
			empty: false,
			children: [],
		},
		{
			name: 'Color and contrast',
			value: 'color-and-contrast',
			empty: false,
			children: [],
		},
		// {
		// 	name: 'Where is it used?',
		// 	value: 'where-to-use',
		// 	empty: false,
		// 	children: [],
		// },
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});

		// Extract styles and sections
		for (var i = 0; i < this.data.length; i++) {
			this.tempSections.push(this.data[i].sectionName);
			this.tempStyles.push(this.data[i].styleName);
		}

		// Sorted and unique styles and sections
		this.sortedSections = [...new Set(this.tempSections)].sort();
		this.sortedStyles = [...new Set(this.tempStyles)].sort();

		// Add the url back at the last moment
		for (var j = 0; j < this.sortedSections.length; j++) {
			let index = this.data.findIndex(
				(x) => this.sortedSections[j] === x.sectionName
			);
			this.sortedSectionsWithUrl.push({
				name: this.sortedSections[j],
				url:
					this.data[index].sectionUrl &&
					this.data[index].sectionUrl + '#styles',
			});
		}
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public changeTypographySort(event): void {
		this.currTyporgraphySort = event.target.value;
	}

	// Return typography by section, sorted by style
	public getTypographyBySection(arr, item: string): Array<any> {
		let tempTypographyArr = arr;
		return tempTypographyArr
			.filter((data) => data.sectionName === item)
			.sort((a, b) => {
				let sa = a.styleName,
					sb = b.styleName;
				if (sa < sb) {
					return -1;
				}
				if (sa > sb) {
					return 1;
				}
				return 0;
			});
	}

	// Return typography by styles, sorted by section
	public getTypographyByStyle(arr, item: string): Array<any> {
		let tempTypographyArr = arr;
		return tempTypographyArr
			.filter((data) => data.styleName === item)
			.sort((a, b) => {
				let sa = a.sectionName,
					sb = b.sectionName;
				if (sa < sb) {
					return -1;
				}
				if (sa > sb) {
					return 1;
				}
				return 0;
			});
	}

	public isRepeatName(str1: string, str2: string): boolean {
		this.currName = str2;
		return str1 === str2 ? true : false;
	}
}
