// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const radioButtonStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Text: focus',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text (instructional text)',
		property: 'color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'f4f5f7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '1f2a37',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'border-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: 'f4f5f7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Container inner fill',
		property: 'background-color',
		value: 'f4f5f7',
		isColorTile: true,
	},
	{
		name: 'Container inner fill (selected)',
		property: 'background-color',
		value: '1c63a5',
		isColorTile: true,
	},
];

export const radioButtonStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Text: focus',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Text (instructional text)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'border-color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Container inner fill',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Container inner fill (selected)',
		property: 'background-color',
		value: '53b1fd',
		isColorTile: true,
	},
];

export const radioButtonStylesTypography = [
	{
		name: 'Label Text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Helper Text',
		property: 'font-family',
		value: '"NotoSans Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.75rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.125rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '18px',
		isColorTile: false,
	},

	// {
	// 	name: 'Text',
	// 	property: 'font-family',
	// 	value: '"Open Sans", Arial, Helvetica, sans-serif',
	// 	isColorTile: false,
	// },
	// {
	// 	name: '',
	// 	property: 'font-size',
	// 	value: '14px',
	// 	isColorTile: false,
	// },
	// {
	// 	name: '',
	// 	property: 'font-weight',
	// 	value: 'normal / 400',
	// 	isColorTile: false,
	// },
];

export const radioButtonStylesStructure = [
	{
		name: 'Container',
		property: 'min-height',
		value: '34px',
		isColorTile: false,
	},
	{
		name: 'Circle',
		property: 'width',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
];
