<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Modal'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Default</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
				<div class="page-live-example-controls">
					<button class="button" (click)="openModal()">Open modal</button>
				</div>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
					<kendo-dialog *ngIf="openedModal" (close)="closeModal('cancel')" [minWidth]="250">
						<div class="k-dialog-titlebar">
							<div class="k-dialog-title">
								<h2>Discard unsaved changes?</h2>
							</div>
						</div>
						<p style="margin: 30px; text-align: center">You can keep editing or
							discard the changes. Discarded changes cannot be recovered.</p>
						<kendo-dialog-actions [layout]="'end'">
							<button kendoButton (click)="closeModal('Keep Editing')" themeColor="secondary">Keep
								Editing</button>
							<button kendoButton (click)="closeModal('Discard Changes')" themeColor="primary">Discard
								Changes</button>
						</kendo-dialog-actions>
					</kendo-dialog>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; DialogModule &#125; from
				'&#64;progress/kendo-angular-dialog';
			</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark">{{ modalHTML }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/dialogs/api/DialogComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Dialog API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/modal#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Primary button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultPrimary" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Secondary button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSecondary" [data]="item"></app-style-row>
					</ul>
					<!-- <span class="page-style-list-title">Danger button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultDanger" [data]="item"></app-style-row>
					</ul> -->
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Primary button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkPrimary" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Secondary button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSecondary" [data]="item"></app-style-row>
					</ul>
					<!-- <span class="page-style-list-title">Danger button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkDanger" [data]="item"></app-style-row>
					</ul> -->
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Primary, secondary, and danger button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Primary, secondary, and danger button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-show">
			<h3 id="subSectionTitleBehaviorsShow" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-show')">
				Overlay <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When a modal is triggered an overlay of 50% opacity covers the entire screen, except the modal. This
					prevents users from interacting with anything but the modal. When the modal is dismissed, the black
					overlay is removed.
				</li>
				<li class="page-section-list-item">
					A box shadow is applied to the modal to make it stand out as a separate element.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\modal_open.png" alt="" />
					<span class="redlines-caption">Example of a modal and the background overlay. The overlay is black
						with 50% opacity.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-actions">
			<h3 id="subSectionTitleBehaviorsActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-actions')">
				Actions <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Every action on a modal dismisses it. There is no close X icon. Users must select an action to
					dismiss the modal, even if it’s cancelling what they were asked to confirm.
					Selecting the area outside of a modal does nothing to dismiss it. Examples of actions are
					confirmations, acknowledgements, and reminders.
				</li>
				<li class="page-section-list-item">
					Actions in a modal are presented using one primary button and one or, in rare cases, two secondary
					buttons. If the modal is being used to confirm a destructive action, use a danger button. These
					buttons appear in the bottom right of the modal. Actions buttons can wrap, if necessary. The primary
					button has focus when the modal opens.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\modal_primary_secondary_button.png" alt="" />
					<span class="redlines-caption">Example of a confirmation modal with a primary and secondary
						button.</span>
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\modal_destructive_action.png" alt="" />
					<span class="redlines-caption">Example of a modal for a destructive action. The primary button has
						the danger style applied.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title page-section-title-empty"
			title="Copy Link to Clipboard" (click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-section-content">
			There are no variations for this component.
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Try to use modals sparingly. They can interrupt flows if a user isn't expecting them. It can also
					get annoying if we ask a user to confirm something they do every day.
				</li>
				<li class="page-section-list-item">
					Use modals to confirm an action when the action is:
					<ul class="page-section-do-list">
						<li class="page-section-list-item"><b>Irreversible</b> — like permanently deleting an item.
						</li>
						<li class="page-section-list-item"><b>Severe</b> — if the action has critical consequences that
							are system-wide or long-term repercussions. </li>
						<li class="page-section-list-item"><b>Complex</b> — like configuring other devices. </li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Title</span>
					A modal title should be short, only a few words. It should be clear, specific, and unambiguous.
				</li>
				<li class="page-section-list-item">
					A confirmation modal should be titled what action the user is confirming and be posed as a question.
					Confirmation modal titles use sentence case.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Delete rule?
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Warning: You are about to delete a rule
							</li>
						</ul>
					</div>
				</li>
			</ul>

			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Body text</span>
					The body text allows you to go into more detail but shouldn’t be more than a few lines.
					Body text should be left-aligned.
				</li>
				<li class="page-section-list-item">
					Confirmation modals should avoid the phrase “are you sure?” It’s a cliché, it doesn’t fit our brand
					voice or hit the right tone, and it doesn’t really prevent the user from doing something.
				</li>
				<li class="page-section-list-item">
					Instead, just focus on the consequences of the action the user is confirming.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Deleting a rule cannot be undone.
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Are you sure you want to proceed? This action cannot be undone.
							</li>
						</ul>
					</div>
				</li>
			</ul>

			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Button</span>
					The primary button should match or mirror the title.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Delete rule
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Continue
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Modals sit on top of the rest of the page content. This includes overlay panels. Modals should
					appear in roughly the middle of the screen.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="resources">
		<h2 id="sectionTitleResources" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('resources')">
			Resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>

		<ul class="page-section-list">
			<li class="page-section-list-item resource-list-item">
				<a href="https://smallishbook.substack.com/p/the-journey-is-theirs" target="_blank">The journey is
					theirs, we just write for it</a>
				<span class="resource-source">Chelsea Larson, Smallish Book</span>
				<a href="https://uxdesign.cc/are-you-sure-you-want-to-do-this-microcopy-for-confirmation-dialogues-1d94a0f73ac6"
					target="_blank">Are you sure you want to do this? Microcopy for confirmation dialogues</a>
				<span class="resource-source">Kinneret Yifrah, Medium</span>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<span class="page-related-list-title">Components and patterns that use
			modals:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="patterns/filter#variations-advanced-filter">Advanced filter pattern</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Modal Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
