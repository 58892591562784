// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const donutChartStylesDefaultTheme = [
	{
		name: 'Slice Color',
		property: 'background-color',
		value: 'f44336',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'e91e9b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'ff9800',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '00bcd4',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '3f51b5',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '2196f3',
		isColorTile: true,
	},
	// {
	// 	name: '',
	// 	property: 'background-color',
	// 	value: '99CCCC',
	// 	isColorTile: true,
	// },
	// {
	// 	name: 'Border',
	// 	property: 'border-color',
	// 	value: 'FF8040',
	// 	isColorTile: true,
	// },
	// {
	// 	name: 'Grid Lines',
	// 	property: 'border-color',
	// 	value: 'E7E9E9',
	// 	isColorTile: true,
	// },
	{
		name: 'Chart Label Lines',
		property: 'stroke',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Chart Legend Text',
		property: 'fill',
		value: '081826',
		isColorTile: true,
	},
];

export const donutChartStylesDefaultThemeSeverity = [
	{
		name: 'Slice Color - Critical',
		property: 'background-color',
		value: '8c2000',
		isColorTile: true,
	},
	{
		name: 'Slice Color - High',
		property: 'background-color',
		value: 'f58509',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Moderate',
		property: 'background-color',
		value: 'fac966',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Low',
		property: 'background-color',
		value: '13bcce',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Informational',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const donutChartStylesDefaultThemeStatus = [
	{
		name: 'Slice Color - Success',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Warning',
		property: 'background-color',
		value: 'E56910',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Error',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Informational',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const donutChartStylesDarkTheme = [
	{
		name: 'Slice Color',
		property: 'background-color',
		value: 'ff8178',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'ff6cfa',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'ffba54',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '00e0fc',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'b1bbf2',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '69bcff',
		isColorTile: true,
	},
	// {
	// 	name: '',
	// 	property: 'background-color',
	// 	value: '99CCCC',
	// 	isColorTile: true,
	// },
	// {
	// 	name: 'Border',
	// 	property: 'border-color',
	// 	value: '1px border that matches the fill color (but solid)',
	// 	isColorTile: false,
	// },
	// {
	// 	name: 'Grid Lines',
	// 	property: 'border-color',
	// 	value: '425563',
	// 	isColorTile: true,
	// },
	{
		name: 'Chart Label Lines',
		property: 'stroke',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Chart Legend Text',
		property: 'fill',
		value: 'fcfcfc',
		isColorTile: true,
	},
];

export const donutChartStylesDarkThemeSeverity = [
	{
		name: 'Slice Color - Critical',
		property: 'background-color',
		value: 'fe5666',
		isColorTile: true,
	},
	{
		name: 'Slice Color - High',
		property: 'background-color',
		value: 'ff9c68',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Moderate',
		property: 'background-color',
		value: 'fcdf7d',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Low',
		property: 'background-color',
		value: '71cfe6',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Informational',
		property: 'background-color',
		value: '6699cc',
		isColorTile: true,
	},
];

export const donutChartStylesDarkThemeStatus = [
	{
		name: 'Slice Color - Success',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Warning',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Error',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Slice Color - Informational',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const donutChartStylesTypography = [
	{
		name: 'Axis Label Text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Chart Legend Text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
];
export const donutChartStylesStructure = [
	{
		name: 'Container Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	}
];
