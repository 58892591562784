// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const textFieldStylesDefaultTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '15293A',
		isColorTile: true
	},
	{
		name: 'Icon (clear icon)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon): hover ',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon background: hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const textFieldStylesDarkTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Icon (clear icon)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon): hover ',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon background: hover',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];

export const textFieldStylesTypography = [
	{
		name: 'Text (label)',
		property: 'font-family',
		value: '"Inter Medium", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'medium / 500',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Input box',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Input box text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Text (instructional and error)',
		property: 'font-family',
		value: '"NotoSans Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.75rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.125rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '18px',
		isColorTile: false,
	}
];

export const textFieldStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '393px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '620px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '16px',
		isColorTile: false,
	},
];
