// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const verticalTabStylesDefaultTheme = [
	{
		name: 'Item',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Item text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Item text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Item text: focus',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Item outline: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Selected item indicator',
		property: 'border-color',
		value: 'FF5500',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
]

export const verticalTabStylesDarkTheme = [
	{
		name: 'Item',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Item text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Item text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Item text: focus',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Item outline: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Selected item indicator',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'color',
		value: '4EC3E0',
		isColorTile: true,
	}
]

export const verticalTabStylesStructure = [
	{
		name: 'Item',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '3rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '1rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '1rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-top-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Container',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Selected item indicator',
		property: 'border-left-width',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Item outline: focus',
		property: 'solid',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Tab Panel Content',
		property: 'align-items',
		value: 'left',
		isColorTile: false,
	},
	{
		name: '',
		property: 'display',
		value: 'flex',
		isColorTile: false,
	},
	{
		name: '',
		property: 'flex-direction',
		value: 'column',
		isColorTile: false,
	},
	{
		name: '* All outside corners',
		property: 'border-radius',
		value: '6px',
		isColorTile: false
	}
]

export const tabStylesDefaultTheme = [
	{
		name: 'Item',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Item (active error)',
		property: 'border-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Item text (active)',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Item text: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Item text: focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Item text (inactive)',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Item text (inactive error)',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Icon (active/inactive error)',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Item text (active error)',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Item outline: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Selected item indicator',
		property: 'border-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
];

export const tabStylesDarkTheme = [
	{
		name: 'Item',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Item (active error)',
		property: 'border-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Item text (active)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Item text: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Item text: focus',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Item text (inactive)',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '242424',
		isColorTile: true,
	},
	{
		name: 'Item text (inactive error)',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Icon (active/inactive error)',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Item text (active error)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Item outline: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Selected item indicator',
		property: 'border-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'background-color',
		value: '242424',
		isColorTile: true,
	},
	{
		name: 'Selected item',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
];

export const tabStylesTypography = [
	{
		name: 'Item text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const tabStylesStructure = [
	{
		name: 'Item',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '3rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '1rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '1rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Selected Item indicator',
		property: 'border-top-width',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Item outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Tab Panel Content',
		property: 'align-items',
		value: 'center',
		isColorTile: false,
	},
	{
		name: '',
		property: 'display',
		value: 'flex',
		isColorTile: false,
	},
	{
		name: '',
		property: 'flex-direction',
		value: 'column',
		isColorTile: false,
	},
	{
		name: '* All outside corners',
		property: 'border-radius',
		value: '6px',
		isColorTile: false
	}
];
