// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: tooltipStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const tooltipStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
];
export const tooltipStylesDarkTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '595959',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
];
export const tooltipStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
];
export const tooltipStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '500px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
];
