// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: breadcrumbStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const breadcrumbStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	}
];
export const breadcrumbStylesDefaultThemeText = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text (visited)',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	}
];
export const breadcrumbStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	}
];
export const breadcrumbStylesDarkText = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text (visited)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	}
];
export const breadcrumbStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"NotoSans Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];
export const breadcrumbStylesTypographyText = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"NotoSans Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'text-decoration',
		value: 'underline',
		isColorTile: false,
	},
];
export const breadcrumbStylesStructure = [
	{
		name: 'Icon',
		property: 'width',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Text',
		property: 'margin-right',
		value: '12px',
		isColorTile: false,
	},
];
export const breadcrumbStylesStructureText = [
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	}
];
//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const breadcrumbStylesDefaultThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];

export const breadcrumbStylesDarkThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const breadcrumbStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const breadcrumbStylesStructureOld = [
	{
		name: 'Text Link',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
