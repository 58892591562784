// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const labelValueStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
];

export const labelValueStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
];

export const labelValueStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
];

export const labelValueStylesTypographyLabel = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Inter Bold", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const labelValueStylesTypographyValue = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Inter Bold", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const labelValueStylesStructure = [
	{
		name: 'Container',
		property: 'max-width',
		value: '620px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '16px',
		isColorTile: false,
	},
];
