// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: numberFieldStylesStructureWide or buttonStylesDarkThemePrimary

export const numberFieldStylesDefaultTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Icon (up and down arrows)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon (up and down arrows): hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Icon background: hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const numberFieldStylesDarkTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Icon (up and down arrows)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (up and down arrows): hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon background: hover',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];

export const numberFieldStylesTypography = [
	{
		name: 'Text (label)',
		property: 'font-family',
		value: '"Inter Medium", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'medium / 500',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Text (input)',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Text (instructional and error)',
		property: 'font-family',
		value: '"NotoSans Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.75rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.125rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '18px',
		isColorTile: false,
	}
];

/*
*/

export const numberFieldStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '120px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false,
	},
	{
		name: 'Icon (up and down arrows)',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Button (for up and down arrow icons)',
		property: 'width',
		value: '28px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'overflow',
		value: 'hidden',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const numberFieldStylesDefaultThemeOld = [
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: '923100',
		isColorTile: true,
	},
];

export const numberFieldStylesDarkThemeOld = [
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const numberFieldStylesTypographyOld = [
	{
		name: 'Field Label',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const numberFieldStylesStructureOld = [
	{
		name: 'Left Icon Button',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Right Icon Button',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
];
