<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Padding is the space between elements on a UI. Spacing is the
				space between two containers in a layout. Together, padding and
				spacing help create clear, functional layouts.
			</li>
		</ul>
	</section>
	<section class="page-section" id="principles">
		<h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('principles')">
			Principles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Create
					relationships and hierarchy.</span>
				Elements near each other are seen as being related. When more
				space is added between elements, their perceived relationship
				weakens. Elements with more spacing around them are usually seen
				as more important than elements that have less space around
				them.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Variance, but
					also consistency.</span>
				Padding and spacing varies by interface, element, and
				relationship. For example, the spacing between two form fields
				is different than the spacing between a form field and a section
				title. There is no way to account for all the potential
				combinations, but following these guidelines ensures clarity and
				functionality across our products.
			</li>
		</ul>
	</section>
	<section class="page-section" id="basics-and-tools">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('basics-and-tools')">
			Basics & tools <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="basics-and-tools-4-px-grid">
			<h3 id="subSectionTitleBasicsAndTools4PxGrid" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('basics-and-tools-4-px-grid')">
				4px grid <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					We use a base grid of 4px. Sometimes spacing inside a
					component needs to be a different number to make the overall
					component hit a desired size, but the spacing between items
					should nearly always be in increments of 4. Half spacing
					(2px) is used, but rarely.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="basics-and-tools-figma-redlines">
			<h3 id="subSectionTitleBasicsAndToolsFigmaRedlines" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('basics-and-tools-figma-redlines')">
				Figma redlines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Redlines are the actual guides in Figma designs that
					communicate the exact padding and spacing specs.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="common-spacing-rules">
		<h2 id="sectionTitleCommonSpacingRules" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('common-spacing-rules')">
			Common spacing rules <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="common-spacing-rules-titles">
			<h3 id="subSectionTitleCommonSpacingRulesTitles" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-titles')">
				Titles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Titles are 20px from the top of a container.
						</li>
						<li class="page-section-list-item">
							Content is typically 16px from both sides of its container.
							A notable exception is grids, which fill their container.
						</li>
						<li class="page-section-list-item">Panels have at least 72px space between the title and the
							right icon, like the closing chevron or X.
						</li>
						<li class="page-section-list-item">Titles for an entire page or panel have 16px below them, but
							these are not to be confused with section headings.</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-section-headings">
			<h3 id="subSectionTitleCommonSpacingRulesSectionHeadings" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-section-headings')">
				Section headings <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Section headings are 24px below the previous sections they
							follow.
						</li>
						<li class="page-section-list-item">
							Content under a section heading starts 8px below it.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-button-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesButtonSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-button-spacing')">
				Button spacing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If a form has sections with buttons, the buttons are 16px below the last field in the
							section.
						</li>
						<li class="page-section-list-item">
							Buttons at the bottom of forms that don’t move, known as
							docked buttons, have a top and bottom spacing of 8px.
						</li>
						<li class="page-section-list-item">Buttons on modals or on panels that don’t belong to a form
							have 16px space between the bottom of the content and the
							buttons.</li>
						<li class="page-section-list-item">Buttons have 8px spacing between other buttons.</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_section_buttons_have_16px_above_them.png">
					</div>
					<span class="redlines-caption">Section buttons have 16px above them.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_button_spacing_panel_buttons_docked_position.png">
					</div>
					<span class="redlines-caption">Docked buttons have 8px above
						and below them.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_button_spacing_panel_buttons_normal_position.png">
					</div>
					<span class="redlines-caption">Panel buttons have 24px above
						them and 8px between them.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-text-fields-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesTextFieldsSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-text-fields-spacing')">
				Text fields spacing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use 16px space between text fields.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-other-component-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesOtherComponentSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard"
				(click)="copyToClipboard('common-spacing-rules-other-component-spacing')">
				Other component spacing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Switches</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							16px between switches
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Checkboxes</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							16px for single checkbox item
						</li>
						<li class="page-section-list-item">
							8px between multiple checkbox items
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Combo box and drop-downs</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Minimum height of 48px for options in a drop-down or combo
							box
						</li>
						<li class="page-section-list-item">
							8px above and below the options in the list
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Radio buttons</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							8px between typical radio buttons with no description
						</li>
						<li class="page-section-list-item">
							8px between descriptive radio button items in a group
						</li>
						<li class="page-section-list-item">
							16px between inline radio buttons in a group
						</li>
						<li class="page-section-list-item">
							16 points between radio buttons with description and a
							related or nested field
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_checkboxes.png">
					</div>
					<span class="redlines-caption">8px between typical checkboxes.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_radio_buttons_descriptive-1.png">
					</div>
					<span class="redlines-caption">8px between radio buttons
						with instructional text and 16px between related or
						nested fields.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_radio_inline.png">
					</div>
					<span class="redlines-caption">16px between inline radio
						buttons.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-panel-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesPanelSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-panel-spacing')">
				Panel spacing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Panels or cards have 8px spacing between them.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-page-application-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesPageApplicationSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard"
				(click)="copyToClipboard('common-spacing-rules-page-application-spacing')">
				Page Application Spacing  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pages have 24px border around them before content begins —
					unless there’s a fixed left menu “stuck” on the left of the
					browser. If so, the content starts 24px to the right of the
					menu, as though there was still a 24px buffer on the entire
					page.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/spacing/spacing_page_application.png">
					</div>
					<span class="redlines-caption">Spacing around page content.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Spacing Guidelines'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>