export const iconsData = [
	{
		name: 'sh-active-directory',
		meta: ['active-directory'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3034&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['ad', 'active directory', 'microsoft', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-active-directory-container',
		meta: ['active-directory-container'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3534&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['file', 'folder', 'ad', 'active directory'],
		colors: [],
	},
	{
		name: 'sh-alarm-clock',
		meta: ['alarm-clock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2870&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['time', 'notification'],
		colors: [],
	},
	{
		name: 'sh-analytics',
		meta: ['analytics'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3570&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['chart'],
		colors: [],
	},
	{
		name: 'sh-android',
		meta: ['android'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-624&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['operating system', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-analytics-reporting',
		meta: ['analytics-reporting'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3538&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['chart'],
		colors: [],
	},
	{
		name: 'sh-apple',
		meta: ['apple'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3414&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['mac', 'operating system', 'computer', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-archive',
		meta: ['archive'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2862&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['file', 'box'],
		colors: [],
	},
	{
		name: 'sh-arrow-circle-left',
		meta: ['arrow-circle-left'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-628&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['west', 'back', 'previous'],
		colors: [],
	},
	{
		name: 'sh-arrow-down',
		meta: ['arrow - down'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3238&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['south'],
		colors: [],
	},
	{
		name: 'sh-arrow-left',
		meta: ['arrow-left'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3250&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['west', 'back', 'previous'],
		colors: [],
	},
	{
		name: 'sh-arrow-right',
		meta: ['arrow - right'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3246&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['east', 'next'],
		colors: [],
	},
	{
		name: 'sh-arrow-up',
		meta: ['arrow-up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3242&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['north'],
		colors: [],
	},
	{
		name: 'sh-asterisk',
		meta: ['asterisk'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3114&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['star'],
		colors: [],
	},
	{
		name: 'sh-atlassian',
		meta: ['atlassian '],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=58883-510&t=zIwTZMRQxP0XiiCG-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-aws',
		meta: ['aws'],
		figma: 'http://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3114&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['logo', 'amazon', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-azure',
		meta: ['azure'],
		figma: 'http://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3114&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['microsoft', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-bandaid-cross',
		meta: ['bandaid - cross'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3354&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['fix'],
		colors: [],
	},
	{
		name: 'sh-bell',
		meta: ['bell'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3478&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['alarm', 'notification'],
		colors: [],
	},
	{
		name: 'sh-binoculars',
		meta: ['binoculars'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3658&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['search'],
		colors: [],
	},
	{
		name: 'sh-blank-document-shield',
		meta: ['blank - document - shield'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=27890-459&mode=design&t=kr5F57VrsKHSGBcO-0',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-book-lightbulb',
		meta: ['book-lightbulb'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3074&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-bookmark',
		meta: ['bookmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3458&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-bridge',
		meta: ['bridge'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3150&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['ad bridge', 'active directory bridge', 'product'],
		colors: [],
	},
	{
		name: 'sh-briefcase',
		meta: ['briefcase'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3302&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['bag'],
		colors: [],
	},
	{
		name: 'sh-firstaid',
		meta: ['firstaid'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3302&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-freebsd',
		meta: ['freebsd'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-648&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['database', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-bt-ad-bridge',
		meta: ['bt - ad - bridge'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3220-268&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['ad bridge', 'active directory bridge', 'product'],
		colors: [],
	},
	{
		name: 'sh-bt-auditor',
		meta: ['bt-auditor'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3038&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['circle', 'checkmark', 'product'],
		colors: [],
	},
	{
		name: 'sh-bt-capture-config',
		meta: ['bt - capture - config'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3042&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bt-cloud-vault',
		meta: ['bt - cloud - vault'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3054&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bt-cpb',
		meta: ['bt - cpb'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3220-282&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product', 'cloud privilege broker'],
		colors: [],
	},
	{
		name: 'sh-bt-dev-ops',
		meta: ['bt - dev - ops'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3058&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['safe', 'product'],
		colors: [],
	},
	{
		name: 'sh-bt-insights',
		meta: ['bt - insights'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3220-296&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product', 'identity security insights'],
		colors: [],
	},
	{
		name: 'sh-bt-logo',
		meta: ['bt'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3223-310&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product', 'beyondtrust'],
		colors: [],
	},
	{
		name: 'sh-bt-ps',
		meta: ['bt - ps'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3223-310&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product', 'password safe'],
		colors: [],
	},
	{
		name: 'sh-bt-pmwm',
		meta: ['bt - pmwm'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3234-419&mode=design&t=LBVeS4KfT7OepZua-4',
		usage: [],
		aliases: ['pbwm', 'lock', 'product', 'privilege management win mac'],
		colors: [],
	},
	{
		name: 'sh-bt-pmul',
		meta: ['bt - pmul'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3234-423&mode=design&t=LBVeS4KfT7OepZua-4',
		usage: [],
		aliases: ['pbul', 'lock', 'product', 'privilege management unix linux'],
		colors: [],
	},
	{
		name: 'sh-bt-pi',
		meta: ['bt - pi'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-326&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['magnifying glass', 'product', 'privilege identity'],
		colors: [],
	},
	{
		name: 'sh-bt-pra',
		meta: ['bt - pra'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-340&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['pra', 'doorway', 'product', 'privileged remote access'],
		colors: [],
	},
	{
		name: 'sh-bt-PBSMC-menu',
		meta: ['bt - PBSMC - menu'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3318&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bt-rapid-deployment',
		meta: ['bt - rapid - deployment'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3066&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bt-ra',
		meta: ['bt - remote - support'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-354&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['headphones', 'headset', 'product', 'remote access'],
		colors: [],
	},
	{
		name: 'sh-bt-response-generator',
		meta: ['bt - response - generator'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3046&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['secrets safe', 'product'],
		colors: [],
	},
	{
		name: 'sh-bt-secrets-safe',
		meta: ['bt - secrets - safe'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-684&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['secrets safe','product'],
		colors: [],
	},
	{
		name: 'sh-bt-team-passwords',
		meta: ['bt - team - passwords'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3062&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bt-vulnerability-mgmt',
		meta: ['bt - vulnerability - mgmt'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3078&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['product'],
		colors: [],
	},
	{
		name: 'sh-bug',
		meta: ['bug'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3098&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['shield'],
		colors: [],
	},
	{
		name: 'sh-building',
		meta: ['building'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3366&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-bullhorn',
		meta: ['bullhorn'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3094&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['megaphone'],
		colors: [],
	},
	{
		name: 'sh-bullseye',
		meta: ['bullseye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3298&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['target'],
		colors: [],
	},
	{
		name: 'sh-calendar',
		meta: ['calendar'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2874&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['time'],
		colors: [],
	},
	{
		name: 'sh-calendar-check',
		meta: ['calendar - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26664-296&mode=design&t=SdbzfVV3OATLHMKX-0',
		usage: [],
		aliases: ['date', 'checkmark'],
		colors: [],
	},
	{
		name: 'sh-camera',
		meta: ['camera'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3086&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['screenshot'],
		colors: [],
	},
	{
		name: 'sh-cancel',
		meta: ['cancel'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2858&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['remove', 'block'],
		colors: [],
	},
	{
		name: 'sh-caret-bottom',
		meta: ['caret - bottom'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3230&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-caret-down',
		meta: ['caret - down'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3274&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-caret-first',
		meta: ['caret - first'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3234&mode=design&t=l0ESCh9gCciEe3yp-0',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-caret-last',
		meta: ['caret - last'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3286&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-caret-left',
		meta: ['caret - left'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3278&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow', 'previous', 'back'],
		colors: [],
	},
	{
		name: 'sh-caret-multi-direction',
		meta: ['caret - multi - direction'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3294&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow', 'up', 'down'],
		colors: [],
	},
	{
		name: 'sh-caret-right',
		meta: ['caret - right'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3282&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['arrow', 'next'],
		colors: [],
	},
	{
		name: 'sh-caret-up',
		meta: ['caret - up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3270&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-check',
		meta: ['check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2830&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['ok', 'success'],
		colors: [],
	},
	{
		name: 'sh-chevron-down',
		meta: ['chevron - down'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3262&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-chevron-left',
		meta: ['chevron - left'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3266&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['previous', 'back', 'arrow'],
		colors: [],
	},
	{
		name: 'sh-chevron-right',
		meta: ['chevron - right'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3258&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['next', 'arrow'],
		colors: [],
	},
	{
		name: 'sh-chevron-up',
		meta: ['chevron - up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3254&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['arrow'],
		colors: [],
	},
	{
		name: 'sh-circle-check',
		meta: ['circle - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2822&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['success'],
		colors: [],
	},
	{
		name: 'sh-circle-exclamation',
		meta: ['circle - exclamation'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2814&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['warning'],
		colors: [],
	},
	{
		name: 'sh-circle-info',
		meta: ['circle - info'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2818&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['information', 'tooltip'],
		colors: [],
	},
	{
		name: 'sh-circle-location',
		meta: ['circle - location'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59416-325&t=HDIGOFFDg8vjN5A4-4',
		usage: [],
		aliases: ['point'],
		colors: [],
	},
	{
		name: 'sh-circle-minus',
		meta: ['circle - minus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2801&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['pause', 'locked'],
		colors: [],
	},
	{
		name: 'sh-circle-pause',
		meta: ['circle - pause'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-704&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-circle-person',
		meta: ['circle - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3510&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['account', 'profile', 'user'],
		colors: [],
	},
	{
		name: 'sh-circle-play',
		meta: ['circle - play'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2954&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-circle-plus',
		meta: ['circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2796&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['create', 'add'],
		colors: [],
	},
	{
		name: 'sh-circle-power',
		meta: ['circle - power'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2930&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['cycle', 'on'],
		colors: [],
	},
	{
		name: 'sh-circle-power-restart',
		meta: ['circle - power - restart'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2934&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-circle-question',
		meta: ['circle - question'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2806&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['tooltip', 'help'],
		colors: [],
	},
	{
		name: 'sh-circle-radioactive',
		meta: ['circle - radioactive'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3514&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-circle-slash',
		meta: ['circle - slash'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10492-251&mode=design&t=f4SkeuVrjJSn2YER-4',
		usage: [],
		aliases: ['error', 'danger'],
		colors: [],
	},
	{
		name: 'sh-circle-stop',
		meta: ['circle - stop'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3130&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-circle-xmark',
		meta: ['circle - xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2810&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['clear', 'close'],
		colors: [],
	},
	{
		name: 'sh-clear-filter',
		meta: ['clear - filter'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3522&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-clipboard',
		meta: ['clipboard'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3166&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-clipboard-magnifying-glass',
		meta: ['clipboard - magnifying - glass'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3618&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['search'],
		colors: [],
	},
	{
		name: 'sh-clipboard-note',
		meta: ['clipboard - note'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26867-332&mode=design&t=dGoOzUIxCxlGnXpr-0',
		usage: [],
		aliases: ['paste'],
		colors: [],
	},
	{
		name: 'sh-clock',
		meta: ['clock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2866&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['time', 'date'],
		colors: [],
	},
	{
		name: 'sh-clock-line',
		meta: ['clock - line'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59355-500&t=JJzPiayhjRdwuViL-4',
		usage: [],
		aliases: ['time', 'date'],
		colors: [],
	},
	{
		name: 'sh-gear-bolt',
		meta: ['gear - bolt'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-632&t=JJzPiayhjRdwuViL-4',
		usage: [],
		aliases: ['events'],
		colors: [],
	},
	{
		name: 'sh-cluster',
		meta: ['cluster'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3494&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-cluster-3-person',
		meta: ['cluster - 3 - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3654&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['organization'],
		colors: [],
	},
	{
		name: 'sh-cmd',
		meta: ['cmd'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2990&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['command'],
		colors: [],
	},
	{
		name: 'sh-code',
		meta: ['code'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3374&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['development', 'tag'],
		colors: [],
	},
	{
		name: 'sh-collapse',
		meta: ['collapse'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3122&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-columns',
		meta: ['columns'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3546&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['choose'],
		colors: [],
	},
	{
		name: 'sh-computer-mouse',
		meta: ['computer - mouse'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3326&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['peripheral'],
		colors: [],
	},
	{
		name: 'sh-computer-registry',
		meta: ['computer - registry'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3322&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['defrag'],
		colors: [],
	},
	{
		name: 'sh-computer-chip',
		meta: ['computer - chip'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=55117-504&t=ck2ijT8mnBu90oMV-4',
		usage: [],
		aliases: ['paths', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-copy',
		meta: ['copy'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3446&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['duplicate'],
		colors: [],
	},
	{
		name: 'sh-database',
		meta: ['database'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3026&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['sql'],
		colors: [],
	},
	{
		name: 'sh-databricks',
		meta: ['databricks'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=58830-495&t=XLDunCtnj8Hxq7R5-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-database-circle-plus',
		meta: ['database - circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3030&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['sql', 'add'],
		colors: [],
	},
	{
		name: 'sh-document',
		meta: ['document'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-640&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['file'],
		colors: [],
	},
	{
		name: 'sh-document-certificate',
		meta: ['document - certificate'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3306&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['file', 'document', 'text'],
		colors: [],
	},
	{
		name: 'sh-document-circle-check',
		meta: ['document - circle - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=28873-1470&mode=design&t=D0lO4y4AqJBZ2b7C-0',
		usage: [],
		aliases: ['checkmark', 'success'],
		colors: [],
	},
	{
		name: 'sh-document-circle-exclamation',
		meta: ['document - circle - exclamation'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3306&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: ['Policy warning'],
		aliases: ['warning'],
		colors: [],
	},
	{
		name: 'sh-document-pencil',
		meta: ['document - pencil'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2886&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-document-scan',
		meta: ['document - scan'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3498&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-document-text',
		meta: ['document - text'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-644&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['file', 'text'],
		colors: [],
	},
	{
		name: 'sh-domain',
		meta: ['domain'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-636&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-dots-vertical',
		meta: ['dots - vertical'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2938&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['menu', 'kebob', 'ellipsis'],
		colors: [],
	},
	{
		name: 'sh-share',
		meta: ['share'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10292-262&mode=design&t=UhmAkj3BcJS9Kmy6-4',
		usage: [],
		aliases: ['connected', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-download',
		meta: ['download'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3014&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-drag',
		meta: ['drag'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2942&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['drag and drop'],
		colors: [],
	},
	{
		name: 'sh-edit',
		meta: ['edit'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2882&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['pencil', 'pen'],
		colors: [],
	},
	{
		name: 'sh-elastic',
		meta: ['elastic'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2882&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-envelope',
		meta: ['envelope'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3502&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['subscribe'],
		colors: [],
	},
	{
		name: 'sh-envelope-outline',
		meta: ['envelope - outline'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3506&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['unsubscribe'],
		colors: [],
	},
	{
		name: 'sh-bt-entitle',
		meta: ['entitle'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=58789-3345&t=HfC1XRYpKwjhOCWS-4',
		usage: [],
		aliases: ['product', 'jit'],
		colors: [],
	},
	{
		name: 'sh-equal',
		meta: ['equal'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26867-324&mode=design&t=dGoOzUIxCxlGnXpr-0',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-exclude',
		meta: ['exclude'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2878&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-expand',
		meta: ['expand'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3206&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['maximize'],
		colors: [],
	},
	{
		name: 'sh-export',
		meta: ['export'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3022&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-eye',
		meta: ['eye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2946&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['show', 'view', 'password'],
		colors: [],
	},
	{
		name: 'sh-eye-slash',
		meta: ['eye - slash'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2950&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['hide', 'password'],
		colors: [],
	},
	{
		name: 'sh-facebook',
		meta: ['facebook'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10410-296&mode=design&t=8sM8raucbNsgbjBL-4',
		usage: [],
		aliases: ['social media'],
		colors: [],
	},
	{
		name: 'sh-document-arrow-up',
		meta: ['document - arrow - up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3598&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['upload', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-circle-eye',
		meta: ['document - circle - eye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3578&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['view', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-circle-plus',
		meta: ['document - circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3574&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['create', 'add', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-floppy',
		meta: ['document - floppy'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26664-288&mode=design&t=SdbzfVV3OATLHMKX-0',
		usage: [],
		aliases: ['save', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-monitor-group',
		meta: ['document - floppy'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26664-292&mode=design&t=SdbzfVV3OATLHMKX-0',
		usage: [],
		aliases: ['network', 'assign', 'computer', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-pdf',
		meta: ['document - pdf'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3586&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['save', 'export', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-script',
		meta: ['document - script'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3602&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['code', 'development', 'file'],
		colors: [],
	},
	{
		name: 'sh-document-shield-check',
		meta: ['document - shield - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3594&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['file'],
		colors: [],
	},
	{
		name: 'sh-document-xls',
		meta: ['document - xls'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3590&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['excel', 'spreadsheet', 'file'],
		colors: [],
	},
	{
		name: 'sh-filter',
		meta: ['filter'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3518&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-fingerprint',
		meta: ['fingerprint'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3442&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['identity', 'credential'],
		colors: [],
	},
	{
		name: 'sh-finish-line',
		meta: ['finish - line'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=29099-1492&mode=design&t=2EbVeQRIy1hv2ZLs-0',
		usage: [],
		aliases: ['empty', 'flag'],
		colors: [],
	},
	{
		name: 'sh-floppy',
		meta: ['floppy'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3198&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['save'],
		colors: [],
	},
	{
		name: 'sh-floppy-arrow-right',
		meta: ['floppy - arrow - right'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3370&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['save', 'load'],
		colors: [],
	},
	{
		name: 'sh-floppy-circle-eye',
		meta: ['floppy - circle - eye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3202&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['save', 'view'],
		colors: [],
	},
	{
		name: 'sh-folder',
		meta: ['folder'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2966&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['file'],
		colors: [],
	},
	{
		name: 'sh-folder-circle-clock',
		meta: ['folder - circle - clock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2982&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['date', 'time'],
		colors: [],
	},
	{
		name: 'sh-folder-circle-eye',
		meta: ['folder - circle - eye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-368&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['view'],
		colors: [],
	},
	{
		name: 'sh-folder-circle-plus',
		meta: ['folder - circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2962&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['create', 'add'],
		colors: [],
	},
	{
		name: 'sh-folder-circle-up',
		meta: ['folder - circle - up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2978&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-folder-ftp',
		meta: ['folder - ftp'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2986&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-folder-open',
		meta: ['folder - open'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2970&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['file'],
		colors: [],
	},
	{
		name: 'sh-folder-open-circle-eye',
		meta: ['folder - open - circle - eye'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2974&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-gear',
		meta: ['gear'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3646&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['settings', 'configuration'],
		colors: [],
	},
	{
		name: 'sh-gear-circle-check',
		meta: ['gear - circle - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3186&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['settings', 'configuration'],
		colors: [],
	},
	{
		name: 'sh-gear-person',
		meta: ['gear - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3158&mode=design&t=7HiPIZAUEYphkcHV-4',
		usage: [],
		aliases: ['settings', 'configuration', 'account', 'user'],
		colors: [],
	},
	{
		name: 'sh-gears',
		meta: ['gears'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3466&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['settings', 'configuration'],
		colors: [],
	},
	{
		name: 'sh-github',
		meta: ['github'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-382&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-gitlab',
		meta: ['gitlab'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=58830-487&t=XLDunCtnj8Hxq7R5-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-github-text',
		meta: ['github'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10023-1562&mode=design&t=EF7dTzcFZripGGPo-4',
		usage: [],
		aliases: ['logo','text','words', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-globe',
		meta: ['globe'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3434&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['world'],
		colors: [],
	},
	{
		name: 'sh-google-cloud-platform',
		meta: ['google', 'cloud', 'platform'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=37476-463&mode=design&t=rdLBlty5xGXzWwrv-4',
		usage: [],
		aliases: ['logo'],
		colors: [],
	},
	{
		name: 'sh-grid-3',
		meta: ['grid - 3'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3006&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['menu'],
		colors: [],
	},
	{
		name: 'sh-grid-2',
		meta: ['grid - 2'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3002&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-grid-2-plus',
		meta: ['grid - 2 - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3010&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['create', 'add'],
		colors: [],
	},
	{
		name: 'sh-datagrid-single',
		meta: ['datagrid - single'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3002&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-datagrid-grouped',
		meta: ['datagrid - grouped'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3010&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-hand-gear',
		meta: ['hand - gear'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3630&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['settings', 'configuration'],
		colors: [],
	},
	{
		name: 'sh-hand-person',
		meta: ['hand - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3626&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['account', 'user'],
		colors: [],
	},
	{
		name: 'sh-heart',
		meta: ['heart'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3558&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['pulse'],
		colors: [],
	},
	{
		name: 'sh-home',
		meta: ['home'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3342&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['house', 'building'],
		colors: [],
	},
	{
		name: 'sh-hourglass',
		meta: ['hourglass'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3554&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['time', 'progress', 'wait'],
		colors: [],
	},
	{
		name: 'sh-id-badge',
		meta: ['id - badge'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2894&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['identification', 'user'],
		colors: [],
	},
	{
		name: 'sh-id-card-clip',
		meta: ['id - card - clip'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3362&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['identification', 'user'],
		colors: [],
	},
	{
		name: 'sh-instagram',
		meta: ['instagram'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10410-304&mode=design&t=8sM8raucbNsgbjBL-4',
		usage: [],
		aliases: ['social media'],
		colors: [],
	},
	{
		name: 'sh-arrow-right-left',
		meta: ['jump'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=57884-273&node-type=FRAME&t=dSsuqQaU46CqThTj-0',
		usage: [],
		aliases: ['jump',],
		colors: [],
	},
	{
		name: 'sh-arrow-down-up',
		meta: ['jump'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=57838-479&node-type=FRAME&t=DCbvjhhqY1QQR0wt-0',
		usage: [],
		aliases: ['jump', 'android'],
		colors: [],
	},
	{
		name: 'sh-key',
		meta: ['key'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3382&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['password', 'credentials'],
		colors: [],
	},
	{
		name: 'sh-keyboard',
		meta: ['keyboard'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3214&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['peripheral'],
		colors: [],
	},
	{
		name: 'sh-ldap',
		meta: ['ldap'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3402&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['microsoft', 'logo'],
		colors: [],
	},
	{
		name: 'sh-lightbulb',
		meta: ['lightbulb'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3346&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['idea'],
		colors: [],
	},
	{
		name: 'sh-lightbulb-circle-plus',
		meta: ['lightbulb - circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3334&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['create', 'add'],
		colors: [],
	},
	{
		name: 'sh-lightbulb-circle-xmark',
		meta: ['lightbulb - circle - xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3338&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['remove', 'clear'],
		colors: [],
	},
	{
		name: 'sh-link',
		meta: ['link'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3106&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['attachment'],
		colors: [],
	},
	{
		name: 'sh-linkedin',
		meta: ['linkedin'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10410-300&mode=design&t=8sM8raucbNsgbjBL-4',
		usage: [],
		aliases: ['social media'],
		colors: [],
	},
	{
		name: 'sh-linux',
		meta: ['linux'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3406&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['penguin', 'operating system', 'logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-location-ip',
		meta: ['location - ip'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3138&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['pin'],
		colors: [],
	},
	{
		name: 'sh-lock',
		meta: ['lock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3526&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['padlock'],
		colors: [],
	},
	{
		name: 'sh-lock-arrow-up-down',
		meta: ['lock - arrow - up - down'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3474&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-lock-asterisks',
		meta: ['lock - asterisks'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3470&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['password'],
		colors: [],
	},
	{
		name: 'sh-logout',
		meta: ['logout'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3210&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-magic',
		meta: ['magic'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3134&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['wand'],
		colors: [],
	},
	{
		name: 'sh-magnifying-glass',
		meta: ['magnifying - glass'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3190&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['search'],
		colors: [],
	},
	{
		name: 'sh-magnifying-glass-minus',
		meta: ['magnifying - glass - minus'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-680&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-magnifying-glass-plus',
		meta: ['magnifying - glass - plus'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-676&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-magnifying-glass-pulse',
		meta: ['magnifying - glass - pulse'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3486&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-menu',
		meta: ['menu'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3622&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['hamburger'],
		colors: [],
	},
	{
		name: 'sh-microsoft-entra',
		meta: ['entra'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10020-1550&mode=design&t=W5NrBfDa6IDccuUT-4',
		usage: [],
		aliases: ['logo'],
		colors: [],
	},
	{
		name: 'sh-monitor',
		meta: ['monitor'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3314&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['computer', 'peripheral'],
		colors: [],
	},
	{
		name: 'sh-monitor-cancel',
		meta: ['monitor - cancel'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3142&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['remove'],
		colors: [],
	},
	{
		name: 'sh-monitor-check',
		meta: ['monitor - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3462&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['success'],
		colors: [],
	},
	{
		name: 'sh-monitor-folder',
		meta: ['monitor - folder'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3482&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: ['zip'],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-monitor-gear',
		meta: ['monitor - gear'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2958&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['configuration', 'settings'],
		colors: [],
	},
	{
		name: 'sh-monitor-group',
		meta: ['monitor - group'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3330&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['network'],
		colors: [],
	},
	{
		name: 'sh-moon-outline',
		meta: ['moon - outline'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3146&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['sleep'],
		colors: [],
	},
	{
		name: 'sh-network',
		meta: ['network'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=58248-468&t=nQfs8W9jbGF6arDb-',
		usage: [],
		aliases: ['paths', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-network-globe',
		meta: ['network - globe'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3490&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['world'],
		colors: [],
	},
	{
		name: 'sh-network-globe-dots',
		meta: ['network - globe - dots'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3642&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['internet'],
		colors: [],
	},
	{
		name: 'sh-not-equal',
		meta: ['not - equal'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=26867-328&mode=design&t=dGoOzUIxCxlGnXpr-0',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-okta',
		meta: ['okta'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3422&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-options',
		meta: ['options'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3566&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['configuration', 'settings'],
		colors: [],
	},
	{
		name: 'sh-organization',
		meta: ['organization'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59416-329&t=HDIGOFFDg8vjN5A4-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-organization-rotate',
		meta: ['organization - rotate'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59416-333&t=HDIGOFFDg8vjN5A4-4',
		usage: [],
		aliases: ['refresh'],
		colors: [],
	},
	{
		name: 'sh-pause',
		meta: ['pause'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-664&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-play',
		meta: ['play'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-668&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-person',
		meta: ['person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2898&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['profile', 'user'],
		colors: [],
	},
	{
		name: 'sh-person-active-directory',
		meta: ['person - active - directory'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3050&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['profile', 'user', 'ad'],
		colors: [],
	},
	{
		name: 'sh-person-attach',
		meta: ['person - attach'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2918&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['organization', 'group'],
		colors: [],
	},
	{
		name: 'sh-person-cluster',
		meta: ['person - cluster'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2922&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-person-share',
		meta: ['person - share'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=15589-1065&mode=design&t=aHJueh1KNUtzz23x-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-person-group-share',
		meta: ['person - share'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=15589-1061&mode=design&t=aHJueh1KNUtzz23x-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-person-group',
		meta: ['person - group'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2926&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['organization'],
		colors: [],
	},
	{
		name: 'sh-person-unlock',
		meta: ['person - unlock'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=2834-2906&t=bvZkcNhs96Tf1ASq-0',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-person-wave',
		meta: ['person - wave'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2850&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['help', 'hello'],
		colors: [],
	},
	{
		name: 'sh-person-key',
		meta: ['person - key'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=55113-463&t=NCWFO0mEympIe6MQ-0',
		usage: [],
		aliases: ['paths', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-person-dormant',
		meta: ['person - dormant'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=2197-161&t=11YTIekPaQQMLOrf-0',
		usage: [],
		aliases: ['paths', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-person-cancel',
		meta: ['person - cancel'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Component-Redlines?node-id=55116-475&t=u291XN6nokmc83rP-0',
		usage: [],
		aliases: ['paths', 'nodes'],
		colors: [],
	},
	{
		name: 'sh-ping-identity',
		meta: ['ping - identity'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3418&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-pivot',
		meta: ['pivot'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3222&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['spreadsheet'],
		colors: [],
	},
	{
		name: 'sh-pivot-attributes',
		meta: ['pivot - attributes'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=3224-396&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['spreadsheet'],
		colors: [],
	},
	{
		name: 'sh-pivot-clear',
		meta: ['pivot - clear'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3226&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['spreadsheet', 'remove', 'clear'],
		colors: [],
	},
	{
		name: 'sh-pivot-grid-view',
		meta: ['pivot - grid - view'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3218&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['spreadsheet', 'view'],
		colors: [],
	},
	{
		name: 'sh-plug',
		meta: ['plug'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3438&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['power'],
		colors: [],
	},
	{
		name: 'sh-plus',
		meta: ['plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2791&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['create', 'add'],
		colors: [],
	},
	{
		name: 'sh-print',
		meta: ['print'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3126&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['printer', 'peripheral'],
		colors: [],
	},
	{
		name: 'sh-rectangle-list-check',
		meta: ['rectangle - list - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3386&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-report',
		meta: ['report'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3170&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['checklist', 'clipboard'],
		colors: [],
	},
	{
		name: 'sh-RNS',
		meta: ['RNS'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3082&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['Registry Name Service'],
		colors: [],
	},
	{
		name: 'sh-rotate',
		meta: ['rotate'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3174&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['refresh', 'reload', 'arrow', 'cycle'],
		colors: [],
	},
	{
		name: 'sh-rotate-left-clock',
		meta: ['rotate - left - clock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3178&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['undo'],
		colors: [],
	},
	{
		name: 'sh-rotate-lock',
		meta: ['rotate - lock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3358&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['refresh'],
		colors: [],
	},
	{
		name: 'sh-sailpoint',
		meta: ['sailpoint'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3394&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-server',
		meta: ['server'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3162&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['stack'],
		colors: [],
	},
	{
		name: 'sh-server-person',
		meta: ['server - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2914&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['administrator'],
		colors: [],
	},
	{
		name: 'sh-shield-check',
		meta: ['shield - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3638&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['checkmark'],
		colors: [],
	},
	{
		name: 'sh-shield-lightbulb',
		meta: ['shield - lightbulb'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3070&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['idea'],
		colors: [],
	},
	{
		name: 'sh-shield-person',
		meta: ['shield - person'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2906&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['user', 'account'],
		colors: [],
	},
	{
		name: 'sh-shield-xmark',
		meta: ['shield - xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3350&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['error', 'cancel', 'remove'],
		colors: [],
	},
	{
		name: 'sh-signal',
		meta: ['signal'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3542&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['linear'],
		colors: [],
	},
	{
		name: 'sh-signs-post',
		meta: ['signs - post'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3090&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['directions'],
		colors: [],
	},
	{
		name: 'sh-slack',
		meta: ['slack'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3398&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-snowflake',
		meta: ['snowflake'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=58830-491&t=XLDunCtnj8Hxq7R5-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-sort-alpha-down',
		meta: ['sort - alpha - down'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3610&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['ascending'],
		colors: [],
	},
	{
		name: 'sh-sort-alpha-up',
		meta: ['sort - alpha - up'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3614&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['descending'],
		colors: [],
	},
	{
		name: 'sh-splunk',
		meta: ['splunk'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3426&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-square-arrow-up-right',
		meta: ['square - arrow - up - right'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3194&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: ['External Link'],
		aliases: ['external link'],
		colors: [],
	},
	{
		name: 'sh-square-check',
		meta: ['square - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A2854&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['selected'],
		colors: [],
	},
	{
		name: 'sh-square-circle-check',
		meta: ['square - circle - check'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2842&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-square-info',
		meta: ['square - info'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-652&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['information'],
		colors: [],
	},
	{
		name: 'sh-square-minus',
		meta: ['square - minus'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-660&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['decrease', 'reduce'],
		colors: [],
	},
	{
		name: 'sh-square-plus',
		meta: ['square - plus'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-656&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['increase', 'increment'],
		colors: [],
	},
	{
		name: 'sh-square-person-wave',
		meta: ['square - person - wave'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2850&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-squares-minus',
		meta: ['squares - minus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3450&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['collapse', 'attributes', 'remove'],
		colors: [],
	},
	{
		name: 'sh-squares-plus',
		meta: ['squares - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3454&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['expand', 'attributes', 'add'],
		colors: [],
	},
	{
		name: 'sh-square-circle-xmark',
		meta: ['square - circle - xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2846&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['clear', 'remove'],
		colors: [],
	},
	{
		name: 'sh-star',
		meta: ['star'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3118&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['favorite'],
		colors: [],
	},
	{
		name: 'sh-star-outline',
		meta: ['star - outline'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-708&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['favorite'],
		colors: [],
	},
	{
		name: 'sh-status-new',
		meta: ['status - new'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-447&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['target', 'radio button'],
		colors: [],
	},
	{
		name: 'sh-status-inprogress',
		meta: ['status - inprogress'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-459&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['in progress'],
		colors: [],
	},
	{
		name: 'sh-status-resolved',
		meta: ['status - resolved'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-471&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['checkmark', 'complete'],
		colors: [],
	},
	{
		name: 'sh-status-falsepositive',
		meta: ['status - falsepositive'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-483&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['detection'],
		colors: [],
	},
	{
		name: 'sh-status-notification',
		meta: ['status - notification'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-495&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['bell', 'new'],
		colors: [],
	},
	{
		name: 'sh-status-ignore',
		meta: ['status - ignore'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=24330-507&mode=design&t=s5DFxNghXnqKLT5G-0',
		usage: [],
		aliases: ['notification', 'bell'],
		colors: [],
	},
	{
		name: 'sh-stop',
		meta: ['stop'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-688&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-table',
		meta: ['table'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2998&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['grid', 'data'],
		colors: [],
	},
	{
		name: 'sh-table-circle-xmark',
		meta: ['table - circle - xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2994&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['remove', 'clear'],
		colors: [],
	},
	{
		name: 'sh-target',
		meta: ['target'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3634&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['bullseye'],
		colors: [],
	},
	{
		name: 'sh-ticket-circle-plus',
		meta: ['ticket - circle - plus'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3378&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-top',
		meta: ['top'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3290&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-trash',
		meta: ['trash'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3650&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['delete', 'remove'],
		colors: [],
	},
	{
		name: 'sh-treemap',
		meta: ['treemap'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3102&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: ['dashboard'],
		colors: [],
	},
	{
		name: 'sh-treemap-view',
		meta: ['treemap - view'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3110&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['dashboard'],
		colors: [],
	},
	{
		name: 'sh-triangle-exclamation',
		meta: ['triangle - exclamation'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2826&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['warning', 'danger', 'caution'],
		colors: [],
	},
	{
		name: 'sh-unlink',
		meta: ['unlink'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3182&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-unlock',
		meta: ['unlock'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3530&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['padlock'],
		colors: [],
	},
	{
		name: 'sh-update',
		meta: ['update'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-672&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: ['refresh'],
		colors: [],
	},
	{
		name: 'sh-upload',
		meta: ['upload'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3018&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-user-management',
		meta: ['user - management'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-700&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-user-plus',
		meta: ['user - plus'],
		figma: 'https://www.figma.com/design/eAoVJlAbfpXNs3PTSX0XIt/SDS-Design-Spec-(formerly-redlines)?node-id=59353-696&t=XWApTkooeGMmsXx0-4',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-user-secret',
		meta: ['user - secret'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834%3A3154&mode=design&t=AMcINcjx3JdE7i1v-1',
		usage: [],
		aliases: [],
		colors: [],
	},
	{
		name: 'sh-virus-total',
		meta: ['virus - total'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3390&mode=design&t=9u6Dym4Tzq93kGyl-4',
		usage: [],
		aliases: ['logo', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-windows',
		meta: ['windows'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-3410&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: [],
		aliases: ['logo', 'microsoft', 'operating system', '3rd party'],
		colors: [],
	},
	{
		name: 'sh-X',
		meta: ['X'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=10410-308&mode=design&t=8sM8raucbNsgbjBL-4',
		usage: [],
		aliases: ['social media', 'twitter'],
		colors: [],
	},
	{
		name: 'sh-xmark',
		meta: ['xmark'],
		figma: 'https://www.figma.com/file/eAoVJlAbfpXNs3PTSX0XIt/Redlines-(Guidance)?type=design&node-id=2834-2834&mode=design&t=AMcINcjx3JdE7i1v-4',
		usage: ['Close'],
		aliases: ['close', 'clear'],
		colors: [],
	},
];
