// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const dataGridStylesDefaultTheme = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Header Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (alternative)',
		property: 'background-color',
		value: 'fafafa',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Grid Row Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'E9EBEC',
		isColorTile: true,
	},
];

export const dataGridStylesDarkTheme = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: '6b6b6b',
		isColorTile: true,
	},
	{
		name: 'Header Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (alternative)',
		property: 'background-color',
		value: '242424',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: '6b6b6b',
		isColorTile: true,
	},
	{
		name: 'Grid Row Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '595959',
		isColorTile: true,
	},
];

export const dataGridtextLinkStylesDefaultDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Text (visited)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text (visited): hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const dataGridStylesTypography = [
	{
		name: 'Column header text',
		property: 'font-family',
		value: '"Inter Medium", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'medium / 500',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Item count text',
		property: 'font-family',
		value: '"Inter Bold", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Grid cell text',
		property: 'font-family',
		value: '"Inter Regular", sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '0.875rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '1.25rem',
		isColorTile: false,
	},
	{
		name: '',
		property: '',
		value: '20px',
		isColorTile: false,
	},
];

export const dataGridStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: 'Icon Menu',
		property: 'justify-content',
		value: 'flex-end',
		isColorTile: false,
	},
	{
		name: 'Grid cell focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'offset',
		value: '-2px',
		isColorTile: false,
	},
	{
		name: 'First Header Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Inner Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Last Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Grid Body',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	
	{
		name: 'Data Row Cell',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	
	{
		name: 'First Data Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},

	{
		name: 'Inner Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},

	{
		name: 'last Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},

	{
		name: 'Grid Cell Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Pagination Container',
		property: 'border-bottom-left-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-right-radius',
		value: '6px',
		isColorTile: false,
	},
];
