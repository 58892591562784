<main class="page-main">
    <app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
    <section class="page-section" id="overview">
        <h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('overview')">
            Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Typography is the style and appearance of text. It serves as both a functional and aesthetic tool, balancing readability with visual appeal. 
                Consistent typography establishes patterns and hierarchy that help guide our users through products. 
            </li>
            <li class="page-section-list-item">
                We use two font styles (Inter and Noto) to create a clear and cohesive hierarchy: one for headings and emphasis, and another for body text and supporting elements. 
                Bold, clear, and purposeful typography is used to establish structure, guide users through content, and reinforce our brand identity.  
            </li>
        </ul>
    </section>
    <section class="page-section" id="fonts">
        <h2 id="sectionTitleFonts" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('fonts')">
            Fonts (typefaces) <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <section class="page-sub-section" id="fonts-inter">
            <h3 id="subSectionTitleFontsOpenSans" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('fonts-open-sans')">
               Inter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <img class="guidance-image" src="\assets\images\redlines\typography\inter.png" alt="" />
                </li>
                <li class="page-section-list-item">
                    Use this typeface for all headings and titles.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="fonts-noto-sans">
            <h3 id="subSectionTitleFontsOpenSans" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('fonts-open-sans')">
               Noto Sans <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <img class="guidance-image" src="\assets\images\redlines\typography\noto_sans.png" alt="" />
                </li>
                <li class="page-section-list-item">
                    This font is used for the body and instructional text such as error text.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="fonts-fira-code">
            <h3 id="subSectionTitleFontsFiraCode" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('fonts-fira-code')">
                Fira Code <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <img class="guidance-image" src="\assets\images\redlines\typography\fira_code.png" alt="" />
                </li>
                <li class="page-section-list-item">
                    This is the font used for all monospace text and code samples.
                </li>
                <!-- <li class="page-section-list-item">
                    This font is typically only used with 14px "body" text styling.
                </li> -->
            </ul>
        </section>
    </section>
    <section class="page-section" id="type-ramp">
        <h2 id="sectionTitleTypeRamp" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('type-ramp')">
            Type ramp (font stack) <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <section class="page-sub-section" id="type-ramp-general">
            <h3 id="subSectionTitleTypeRampGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-general')">
                General
                 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Font scale and weight work together to create hierarchy, larger font catches the eye first. Page title should always use Heading 1, font size 20px, this should be used for Page title only. 
                    We also have two larger font sizes to accommodate some legacy components which should not be used elsewhere.  Anything smaller than 12px should not be used because it’s not accessible.  
                </li>
                <li class="page-section-list-item">
                    Weight refers to font boldness or thickness of characters in that typeface. We use semibold, bold, medium, and regular weight. 
                    We don’t use lightweight because it can be hard to read and often doesn’t meet accessibility standards.
                </li>
                <li class="page-section-list-item">
                    These fonts should not be altered — no case or spacing changes like all caps. <a
                        href="components/text-link#styles">Text links</a> are underlined
                    and bold styling can be used sparingly in body text for emphasis, or to reference UI titles when
                    writing
                    instructions.
                </li>
                <li class="page-section-list-item">
                    <img class="font-image" src="\assets\images\redlines\typography\font_display.png" alt="" />
                </li>
                <!-- <li class="page-section-list-item">
                    <ul class="page-ramp-list">
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list page-ramp-list-item-header">
                                <li class="page-ramp-sub-list-item page-ramp-list-item-header">
                                    Name
                                </li>
                                <li class="page-ramp-sub-list-item page-ramp-list-item-header">
                                    Weight
                                </li>
                                <li class="page-ramp-sub-list-item page-ramp-list-item-header">
                                    Size / Line height
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h1">
                                    Page title
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Bold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    20px / 24px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h2">
                                    Section title
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    18px / 22px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h3">
                                    Sub-section title
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    16px / 20px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h3">
                                    Button text
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    16px / 20px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h4">
                                    Sub-section title
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    14px / 18px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-h4">
                                    Label
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    14px / 18px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-body">
                                    Body
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Normal
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    14px / 18px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-body-bold">
                                    Body (Bold)
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Bold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    14px / 18px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-badge">
                                    Badge
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Semibold
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    12px / 18px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-error">
                                    Error
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Normal
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    12px / 16px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-instructional">
                                    Instructional
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Normal
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    12px / 16px
                                </li>
                            </ul>
                        </li>
                        <li class="page-ramp-list-item">
                            <ul class="page-ramp-sub-list">
                                <li class="page-ramp-sub-list-item text-styles-supporting">
                                    Supporting
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    Normal
                                </li>
                                <li class="page-ramp-sub-list-item">
                                    12px / 16px
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li> -->
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-headers">
            <h3 id="subSectionTitleTypeRampTitles" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-headers')">
                Heading <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Headings establish a clear hierarchy within our interface. They guide users through the content structure, making it easier to scan and understand.
                    The different levels of headings create a hierarchy of information. Smaller headings are subordinate to larger ones.  
                </li>
                <li class="page-section-list-item">
                    Avoid using more than three heading styles on the same page. When creating a heading, you also need to consider that it should be easy to scan. 
                    Use simple language. A good heading is a short sentence, without commas, periods, hyphens, or semicolons. These are intended for H1-H4 HTML tags 
                    on a page and follow a strict page hierarchy. For more information, go to <a href="guidelines/typography#html-tags-hierarchy">page hierarchy</a>.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-button">
            <h3 id="subSectionTitleTypeRampButton" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-button')">
                Button <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Buttons are critical interactive elements that guide users through tasks.  
                </li>
                <li class="page-section-list-item">
                    <!-- This is the text most often used for <a href="components/button#styles">button</a> text. -->
                    Use Inter font, 16px for button text to ensure readability. Maintain clear visual states (default, hover, pressed, and disabled) to provide feedback. Design buttons with distinct styles for hierarchy: 
                </li>
                <li>
                    <ul class="page-section-do-list">
						<li class="page-section-list-item">
							Primary: For key actions (e.g. "Submit"). 
						</li>
						<li class="page-section-list-item">
							Secondary: For less critical actions (e.g. "Cancel"). 
						</li>
                        <li class="page-section-list-item">
							Text button: For low-priority actions (e.g. "Learn More”).
						</li>
					</ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-label">
            <h3 id="subSectionTitleTypeRampLabel" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-label')">
                Label <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Labels are primarily used for HTML form elements and are styled with a medium weight to ensure clarity and distinction. 
                    In cases where labels are part of label/value pairs, a mix of weights is used: Bold weight is applied to emphasize labels, while regular weight is used for de-emphasized labels to allow the value to stand out more prominently. 
                    Medium-weight labels are still used as column headers to help them stand out from surrounding content and improve scan ability in data tables or lists. 
                    This approach ensures a flexible and intentional use of weights to emphasize or de-emphasize labels based on context. 
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-body">
            <h3 id="subSectionTitleTypeRampBody" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-body')">
                Body <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Use for all body copy that isn’t instructional, error or supporting text. Body text can be bold to emphasize certain words but use bold sparingly, so it does not conflict or compete with surrounding typography.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-instructional">
            <h3 id="subSectionTitleTypeRampInstructional" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-instructional')">
                Instructional text <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Instructional text is used to provide guidance, hints, or additional context to users and is styled with a regular weight to ensure it remains unobtrusive and easy to read. 
                    This is typically applied to text under text fields or elements that require supporting text.  The regular weight ensures that the instructional text does not compete with primary content, maintaining a clear visual hierarchy.  
                </li>
                <li class="page-section-list-item">
                    Instructional text is also used in badges or other elements that require emphasis; a bold weight is used to ensure visibility and draw attention.  
                </li>
                <li class="page-section-list-item">
                    This balance between regular and bold weights ensures instructional text is styled appropriately to align with its purpose and context. 
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-error">
            <h3 id="subSectionTitleTypeRampError" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-error')">
                Error <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Error text should be styled with a regular font weight.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="type-ramp-supporting">
            <h3 id="subSectionTitleTypeRampSupporting" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('type-ramp-supporting')">
                Supporting content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Use for the supplementary text that goes under labels on form controls, like the supporting content under <a href="components/checkbox#variations-supporting-content">checkboxes</a>.
                </li>
            </ul>
        </section>
    </section>
    <section class="page-section" id="html-tags-hierarchy">
        <h2 id="sectionTitleHtmlTagsHierarchy" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('html-tags-hierarchy')">
            HTML tags and page hierarchy <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <section class="page-sub-section" id="html-tags-hierarchy-general">
            <h3 id="subSectionTitleHtmlTagsHierarchyGeneral" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('html-tags-hierarchy-general')">
                General
                 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    A good page hierarchy and using semantic HTML tags play an important role in meeting accessibility
                    requirements.
                </li>
                <li class="page-section-list-item">
                    Do not skip levels of an H tag (having an H4 tag directly below an H2 tag, for example) as it
                    confuses screen readers
                    and breaks the hierarchy of a page.
                </li>
                <li class="page-section-list-item">
                    <div class="redlines-container">
						<img src="/assets/images/redlines/typography/typography_example_page_hierarchy.png">
					</div>
                    <span class="redlines-caption">Example of page hierarchy (for H tag reference only).</span>
                </li>
            </ul>
        </section>
    <!--<section class="page-sub-section" id="html-tags-hierarchy-h1">
            <h3 id="subSectionTitleHTMLTagsHierarchyH1" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('html-tags-hierarchy-h1')">
                H1 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    The &lt;H1&gt; tag is reserved for the page title.
                </li>
                <li class="page-section-list-item">
                    There shouldn't be any other occurances of an &lt;H1&gt; on a page.
                </li>
                <li class="page-section-list-item">
                    <ul class="page-sample-list">
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">
                            Styles
                        </li>

                        <li class="page-sample-list-item">
                            <ul class="page-sample-styles-list">
                                <li class="page-section-list-item">
                                    font-size: 20px
                                </li>
                                <li class="page-section-list-item">
                                    font-weight: bold / 700
                                </li>
                                <li class="page-section-list-item">
                                    line-height: 24px
                                </li>
                            </ul>
                            <ul class="page-section-list">
                                <li class="page-section-list-item">
                                </li>
                            </ul>
                        </li>
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">Sample
                        </li>
                        <li class="page-sample text-styles-h1">
                            The quick brown fox jumps over the lazy dog.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="html-tags-hierarchy-h2">
            <h3 id="subSectionTitleHTMLTagsHierarchyH2" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('html-tags-hierarchy-h2')">
                H2 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    &lt;H2&gt; tags uses the section title styles.
                </li>
                <li class="page-section-list-item">
                    <ul class="page-sample-list">
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">
                            Styles
                        </li>
                        <li class="page-sample-list-item">
                            <ul class="page-sample-styles-list">
                                <li class="page-section-list-item">
                                    font-size: 18px
                                </li>
                                <li class="page-section-list-item">
                                    font-weight: semibold / 600
                                </li>
                                <li class="page-section-list-item">
                                    line-height: 22px
                                </li>
                            </ul>
                            <ul class="page-section-list">
                                <li class="page-section-list-item">
                                </li>
                            </ul>
                        </li>
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">Sample
                        </li>
                        <li class="page-sample text-styles-h2">
                            The quick brown fox jumps over the lazy dog.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="html-tags-hierarchy-h3">
            <h3 id="subSectionTitleHTMLTagsHierarchyH3" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('html-tags-hierarchy-h3')">
                H3 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    &lt;H3&gt; tags use sub-section styling.
                </li>
                <li class="page-section-list-item">
                    <ul class="page-sample-list">
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">
                            Styles
                        </li>
                        <li class="page-sample-list-item">
                            <ul class="page-sample-styles-list">
                                <li class="page-section-list-item">
                                    font-size: 16px
                                </li>
                                <li class="page-section-list-item">
                                    font-weight: semibold / 600
                                </li>
                                <li class="page-section-list-item">
                                    line-height: 20px
                                </li>
                            </ul>
                            <ul class="page-section-list">
                                <li class="page-section-list-item">
                                </li>
                            </ul>
                        </li>
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">Sample
                        </li>
                        <li class="page-sample text-styles-h3">
                            The quick brown fox jumps over the lazy dog.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="html-tags-hierarchy-h4">
            <h3 id="subSectionTitleHTMLTagsHierarchyH4" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('html-tags-hierarchy-h4')">
                H4 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    &lt;H4&gt; tags shares text styling with labels. &lt;H4&gt; tags are not interactive.
                </li>
                <li class="page-section-list-item">
                    <ul class="page-sample-list">
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">
                            Styles
                        </li>

                        <li class="page-sample-list-item">
                            <ul class="page-sample-styles-list">
                                <li class="page-section-list-item">
                                    font-size: 14px
                                </li>
                                <li class="page-section-list-item">
                                    font-weight: semibold / 600
                                </li>
                                <li class="page-section-list-item">
                                    line-height: 18px
                                </li>
                            </ul>
                            <ul class="page-section-list">
                                <li class="page-section-list-item">
                                </li>
                            </ul>
                        </li>
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">Sample
                        </li>
                        <li class="page-sample text-styles-h4">
                            The quick brown fox jumps over the lazy dog.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="html-tags-hierarchy-label">
            <h3 id="subSectionTitleHTMLTagsHierarchyLabel" class="page-sub-section-title" title="Copy Link to Clipboard"
                (click)="copyToClipboard('html-tags-hierarchy-label')">
                Label <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    A &lt;label&gt; tag is intended for HTML form elements.
                </li>
                <li class="page-section-list-item">
                    <ul class="page-sample-list">
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">
                            Styles
                        </li>

                        <li class="page-sample-list-item">
                            <ul class="page-sample-styles-list">
                                <li class="page-section-list-item">
                                    font-size: 14px
                                </li>
                                <li class="page-section-list-item">
                                    font-weight: semibold / 600
                                </li>
                                <li class="page-section-list-item">
                                    line-height: 18px
                                </li>
                            </ul>
                            <ul class="page-section-list">
                                <li class="page-section-list-item">
                                </li>
                            </ul>
                        </li>
                        <li class="page-sample-list-item" style="font-size:16px; font-weight: bold;">Sample
                        </li>
                        <li class="page-sample text-styles-h4">
                            The quick brown fox jumps over the lazy dog.
                        </li>
                    </ul>
                </li>
            </ul>
        </section> -->
    </section>
    <section class="page-section" id="line-length-and-alignment">
        <h2 id="sectionTitleLineLengthAndAlignment" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('line-length-and-alignment')">
            Line length and alignment <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Line length is the number of characters on each line of text. Line length should be between 40 and 60 characters for body copy, and 20 and 50 for headings.
            </li>
            <li class="page-section-list-item">
                <img class="guidance-image" src="\assets\images\typography_line_length.png" alt="" />
            </li>
            <li class="page-section-list-item">
                The baseline is the invisible horizontal line that text sits on. Use the baseline to align text with icons. 
            </li>
            <li class="page-section-list-item">
                We do not justify text (when all the lines are the same length) because it isn’t accessible due to all the extra white space between words. This is confusing for screen readers. 
            </li>
            <li class="page-section-list-item">
                Center-aligned text is not visually pleasing. Use left-aligned text, which works best for English and most languages. 
            </li>
        </ul>
    </section>
    <section class="page-section" id="color-and-contrast">
        <h2 id="sectionTitleColorAndContrast" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('color-and-contrast')">
            Color and contrast <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Color helps reinforce hierarchy. Using too many colors can create distraction. 
            </li>
            <li class="page-section-list-item">
                Color can also be used to signal meaning. For example, error text can be red to signal that something has gone wrong, and text links are a different color to differentiate them from the surrounding words. 
            </li>
            <li class="page-section-list-item">
                To keep content accessible, stick to a contrast ratio of at least 4.5:1 between the font and the background.
            </li>
            <!-- <li class="page-section-list-item" style="max-width: 800px;">
                <ul class="page-color-ramp-list">
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list page-color-ramp-list-item-header">
                            <li class="page-color-ramp-sub-list-item page-color-ramp-list-item-header">
                                Name
                            </li>
                            <li class="page-color-ramp-sub-list-item page-color-ramp-list-item-header">
                                Light Theme Color
                            </li>
                            <li class="page-color-ramp-sub-list-item page-color-ramp-list-item-header">
                                Dark Theme Color
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h1">
                                Page title
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h2">
                                Section title
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h3">
                                Sub-section title
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h3">
                                Button text
                            </li>
                            <li class="page-color-ramp-sub-list-item"
                                style="width:66%; flex-direction: row; justify-content: flex-start;">
                                See <a href="components/button#styles" style="margin-left:5px; margin-right: 5px;">
                                    button component </a> for color usage.
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h4">
                                Sub-section title
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-h4">
                                Label
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-body">
                                Body
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-body-bold">
                                Body (Bold)
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'081826'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'fcfcfc'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-badge">
                                Badge
                            </li>
                            <li class="page-color-ramp-sub-list-item"
                                style="width:66%; flex-direction: row; justify-content: flex-start;">
                                See <a href="components/badge#styles" style="margin-left:5px; margin-right: 5px;">
                                    badge component </a> for color usage.
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-error">
                                Error
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'C1001B'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'FF8593'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-instructional">
                                Instructional
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'15293A'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'E7E9E9'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                    <li class="page-color-ramp-list-item">
                        <ul class="page-color-ramp-sub-list">
                            <li class="page-color-ramp-sub-list-item text-styles-supporting">
                                Supporting
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'15293A'"></app-color-tile>
                            </li>
                            <li class="page-color-ramp-sub-list-item">
                                <app-color-tile [hexValue]="'E7E9E9'"></app-color-tile>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li> -->
        </ul>
    </section>
    <!-- <section class="page-section" id="where-to-use">
        <h2 id="sectionTitleWhereToUse" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('where-to-use')">
            Where is it used? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <div class="page-typography-sort-header">
            <span style="font-weight:20px;">Sorted by</span> <select class="page-typography-select"
                (change)="changeTypographySort($event)" title="Typography Sort">
                <option value="component">Component / pattern</option>
                <option value="style">Typography style</option>
            </select>
        </div>
        <ul class="page-typography-sort-list" *ngIf="currTyporgraphySort === 'component'">
            <li class="page-typography-sort-list-item" *ngFor="let item of sortedSectionsWithUrl">
                <span class="page-typography-sort-title">{{ item.name }} <a class="page-typography-sort-title-link"
                        *ngIf="item.url" [attr.href]="item.url">[Guidance]</a></span>
                <ul class="page-typography-sort-sub-list">
                    <li class="page-style-list-item page-style-list-item-header">
                        <span>Style Name</span><span>Used for</span>
                    </li>
                    <li class="page-style-list-item" *ngFor="let subItem of getTypographyBySection(data, item.name)">
                        <span [className]="subItem.className">{{ subItem.styleName }}</span><span>{{
                            subItem.description
                            }}</span>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="page-typography-sort-list" *ngIf="currTyporgraphySort === 'style'">
            <li class="page-typography-sort-list-item" *ngFor="let item of sortedStyles">
                <span class="page-typography-sort-title">{{ item }}</span>
                <ul class="page-typography-sort-sub-list">
                    <li class="page-style-list-item page-style-list-item-header">
                        <span>Component / pattern name</span><span>Used for</span>
                    </li>
                    <li class="page-style-list-item" *ngFor="let subItem of getTypographyByStyle(data, item)">
                        <a [attr.href]="subItem.sectionUrl">{{ subItem.sectionName }}</a><span>{{
                            subItem.description
                            }}</span>
                    </li>
                </ul>
            </li>
        </ul>
    </section> -->
    <section class="page-section" id="resources">
        <h2 id="sectionTitleResources" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('resources')">
            Resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>

        <ul class="page-section-list">
            <li class="page-section-list-item resource-list-item">
                <a href="https://usability.yale.edu/web-accessibility/articles/headings#using" target="_blank">Usability
                    & Web Accessibility: Headings</a><span class="resource-source">Yale University</span>
            </li>
            <li class="page-section-list-item resource-list-item">
                <a href="https://github.com/tonsky/FiraCode" target="_blank">Fira Code Font</a><span
                    class="resource-source">tonsky, Github</span>
            </li>
        </ul>
    </section>
    <section class="page-section" id="history">
        <h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('history')">
            Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-history-list">
            <li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Typography Guidelines'
						)
					">
                <span class="page-history-list-item-title">{{
                    changelogService.formatDate(subItem.changeDate)
                    }}</span>
                <a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
            </li>
        </ul>
    </section>
</main>